body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*////////////// SCROLL /////////////////////*/
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 69, 0, 0.4);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 69, 0, 0.2);
}

::-webkit-scrollbar-corner {
  background: inherit;
}

/*//////////////////////////////////////////////////*/
