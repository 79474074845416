.modalWrapper{
    /*position: fixed;*/
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(142, 142, 142, 0.58);
    z-index: 1101;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
}
.modalWrapperClose{
    position: relative;
    left: 20px;
    top: -20px;
    float: right;
    width: 40px;
    cursor: pointer;
}
.modalWrapperClose:hover{
    color: darkred;
}
.modalInner{
    position: relative;
    /* background-color: white; */
    border-radius: 10px;
    /* width: 95%; */
    height: 95%;
    /* margin: 70px auto 10px; */
    overflow: hidden;
}
.headerBtnBlock{
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}