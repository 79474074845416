
.calendarBlock{
    width: 100%;
    /*border: 1px solid;*/
    border-radius: 15px;
    /*padding: 5px;*/
    /*background-color: #f8f4ef;*/
    background-color: white;
    overflow: hidden;
    /*height: 40vh;*/
    max-width: 500px;
}
.navigateBlock{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    background-color: white;
    margin-top: 10px;
}
.dateShowBlock{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 40%;
    justify-content: center;
}
.dateShowBlock div{
    margin-right: 5px;
    margin-left: 5px;
}
.daysNameBlock{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: white;
    /* padding: 10px; */
    justify-content: space-evenly;
}
.daysNameBlock div{
    height: 1rem;
    width: 10vw;
    text-align: center;
    margin-left: 2px;
    padding-left: 2px;
    margin-right: 2px;
    padding-right: 2px;
}
.weeksListBlock{
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
}
.weekBlock{
    display: flex;
    width: 100%;
    height: 3rem;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.dayElem{
    height: 100%;
    width: 100%;
    border: 2px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    margin: 2px;
    padding: 2px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #ffffff;
    overflow: hidden;
}
.dayElemCurrent{
    border-color: #ac2777;
    /*border-color: darkgreen;*/
    /*background-color: #ffffff*/
}
.colorsBlock{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-end;
    width: 100%;
}
.colorElem{
    min-height: 3px;
    min-width: 95%;
    padding: 1px;
}
.eventColorsBlock{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.evColorElem{
    height: 5px;
    width: 5px;
    margin: 1px;
    border-radius: 50%;
}
.monthBlock{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.selectedPeriodBlockWrapper{
    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    display: flex;
    flex-direction: column;
    /*background-color: #EBEAE9;*/
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
}
.selectedPeriodBlockInner{
    width: 95%;
    /*height: 80%;*/
    padding: 10px;
    /*border: 1px solid black;*/
}
.selectedBlocksWrapper{

    /*height: 95%;*/
    display: flex;
}
.yearBlock{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    /*height: 7rem;*/
    overflow-y: auto;
    width: 100px;
    padding: 5px;
    /*height: 100%;*/
}
.yearElem{
    padding: 7px;
    margin-top: 3px;
    cursor: pointer;
}
.yearElemSelected{
    padding: 7px;
    margin-top: 3px;
    border-radius: 10px;
    color: white;
    background-color: #27ac69;
}

.monthBlockForSelect{
    position: relative;
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    height: 100%;
    overflow: auto;
    flex-wrap: nowrap;
    align-items: stretch;
}
.monthElem{
    width: 150px;
    padding: 8px;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 15px;
    margin: 3px;
    text-align: center;
}
.monthElem:hover{
    background-color: #27ac69;
    color: #ffffff;
}
.monthElemSelected{
    background-color: #27ac69;
    color: #ffffff;
}
